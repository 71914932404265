import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithBackground.js";
import ContactUsForm from "components/forms/TwoColContactUsWithIllustrationFullForm.js";
import ContactDetails from "components/cards/ThreeColContactDetails.js";

const Address = tw.span`leading-relaxed`;
const AddressLine = tw.span`block`;
const Email = tw.span`text-sm mt-6 block text-gray-500`;
const Phone = tw.span`text-sm mt-0 block text-gray-500`;

export default () => {
  return (
    <AnimationRevealPage>
      <Header />
      <ContactUsForm />
      <ContactDetails
        cards={[
          {
            title: "Mumbai, Maharashtra",
            description: (
              <>
                <Address>
                  <AddressLine>Four Bungalows,</AddressLine>
                  <AddressLine>Andheri West 400053</AddressLine>
                </Address>
                <Email to="mailto:support@shulkpay.com">
                  support@shulkpay.com
                </Email>
                <Phone to="tel:+91 8976120023">+91 8976120023</Phone>
              </>
            ),
          },
          
        ]}
      />
      <Footer />
    </AnimationRevealPage>
  );
};
