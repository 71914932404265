import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithBackground.js";
import MainFeature1 from "components/features/TwoColWithButton.js";
// import MainFeature2 from "components/features/TwoColSingleFeatureWithStats.js";
// import MainFeature3 from "components/features/TwoColSingleFeatureWithStats2.js";
import Features from "components/features/ThreeColSimple.js";
// import Features from "components/features/ThreeColWithSideImage.js";

//import SupportIconImage from "images/support-icon.svg";
//import ShieldIconImage from "images/shield-icon.svg";
//import CustomerLoveIconImage from "images/simple-icon.svg";

const Subheading = tw.span`uppercase tracking-wider text-sm`;

const Payouts = () => {
  return (
    <AnimationRevealPage>
      <Header />
      <MainFeature1
        subheading={<Subheading></Subheading>}
        heading="Quick, Fast & Uncomplicated Payouts"
        description="Experience seamless and timely payouts with our efficient feature. Streamline your financial transactions for a smoother business operation."
        buttonRounded={false}
        primaryButtonText=""
        imageSrc="https://s3.ap-southeast-1.wasabisys.com/shulkpaywebsite/payouts1.jpg"
      />
      <MainFeature1
        subheading={<Subheading></Subheading>}
        heading="Pay via UPI, IMPS, NEFT or RTGS"
        description="Choose your preferred payment method – UPI, IMPS, NEFT, or RTGS – for seamless and convenient transactions. Your flexibility, our priority."
        buttonRounded={false}
        primaryButtonText=""
        imageSrc="https://s3.ap-southeast-1.wasabisys.com/shulkpaywebsite/payouts2.jpg"
        textOnLeft={false}
      />
      <Features
        subheading={<Subheading></Subheading>}
        heading="Payouts"
        description="Simplify your financial transactions with our Payouts solution. 
        Secure, swift, and straightforward integration for hassle-free fund transfers."
        cards={[
          {
            imageSrc: "https://s3.ap-southeast-1.wasabisys.com/shulkpaywebsite/multiplemode.svg",
            title: "Multiple Modes of Transfer",
            description: "Enjoy versatile fund transfers with our Multiple modes of transfer. Convenience and flexibility at your fingertips."
          },
          {
            imageSrc: "https://s3.ap-southeast-1.wasabisys.com/shulkpaywebsite/secured.svg",
            title: "Secured",
            description: "Rest easy with our Secured Payout option. Your financial transactions are protected with top-notch security measures "
          },
          {
            imageSrc: "https://s3.ap-southeast-1.wasabisys.com/shulkpaywebsite/seamless.svg",
            title: "Seamless",
            description: "Experience the ease of seamless fund transfers with our Payouts feature. Effortless transactions for your peace of mind."
          },
        ]}
        linkText=""
      />
     
      <Footer />
    </AnimationRevealPage>
  )
}

export default Payouts