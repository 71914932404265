import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithBackground.js";
import MainFeature1 from "components/features/TwoColWithButton.js";
// import MainFeature2 from "components/features/TwoColSingleFeatureWithStats.js";
// import MainFeature3 from "components/features/TwoColSingleFeatureWithStats2.js";
import Features from "components/features/ThreeColSimple.js";
// import Features from "components/features/ThreeColWithSideImage.js";

//import SupportIconImage from "images/support-icon.svg";
//import ShieldIconImage from "images/shield-icon.svg";
//import CustomerLoveIconImage from "images/simple-icon.svg";

const Subheading = tw.span`uppercase tracking-wider text-sm`;

const UpiAutoPay = () => {
  return (
    <AnimationRevealPage>
    <Header />
    <MainFeature1
      subheading={<Subheading></Subheading>}
        heading="Automate your recurring collections "
        description="UPI AutoPay automates your recurring payments seamlessly. Set up recurring payments for subscriptions, bills, or any regular expenses, and let the system handle the rest"
      buttonRounded={false}
      primaryButtonText=""
      imageSrc="https://s3.ap-southeast-1.wasabisys.com/shulkpaywebsite/autopay.svg"
    />
    <MainFeature1
      subheading={<Subheading></Subheading>}
        heading="UPI AutoPay, best way to collect recurring payments!"
        description="Discover the ultimate solution for hassle-free recurring payments – UPI AutoPay. Simplify collections effortlessly and securely, ensuring a seamless financial experience for both you and your customers. Say goodbye to manual transactions and hello to the future of payment convenience."
      buttonRounded={false}
      primaryButtonText=""
      imageSrc="https://s3.ap-southeast-1.wasabisys.com/shulkpaywebsite/autopay2.jpg"
      textOnLeft={false}
    />
    <Features
      subheading={<Subheading></Subheading>}
      heading="UPI AutoPay"
      description="Optimize recurring payments with UPI AutoPay. Streamline collections effortlessly and securely for a frictionless financial experience."
      cards={[
        {
          imageSrc: "https://s3.ap-southeast-1.wasabisys.com/shulkpaywebsite/seamless.svg",
          title: "Efficiency",
          description: "Reduce administrative tasks and streamline payment processes."
        },
        {
          imageSrc: "https://s3.ap-southeast-1.wasabisys.com/shulkpaywebsite/easy.svg",
          title: "Convenience",
          description: "Eliminate manual transactions, ensuring timely payments. "
        },
        {
          imageSrc: "https://s3.ap-southeast-1.wasabisys.com/shulkpaywebsite/secured.svg",
          title: "Security",
          description: "Robust encryption and authentication protocols protect your financial data."
        },
      ]}
      linkText=""
    />
    
    <Footer />
  </AnimationRevealPage>
  )
}

export default UpiAutoPay