import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithBackground.js";
import MainFeature1 from "components/features/TwoColWithButton.js";
// import MainFeature2 from "components/features/TwoColSingleFeatureWithStats.js";
// import MainFeature3 from "components/features/TwoColSingleFeatureWithStats2.js";
import Features from "components/features/ThreeColSimple.js";
// import Features from "components/features/ThreeColWithSideImage.js";

//import SupportIconImage from "images/support-icon.svg";
//import ShieldIconImage from "images/shield-icon.svg";
//import CustomerLoveIconImage from "images/simple-icon.svg";

const Subheading = tw.span`uppercase tracking-wider text-sm`;

const PaymentLinks = () => {
  return (
    <AnimationRevealPage>
    <Header />
    <MainFeature1
      subheading={<Subheading></Subheading>}
        heading="Fast, Reliable & Secure Payment Links"
        description="Our Payment Links are designed for simplicity. Just generate a unique link, share it with your recipient, and they can easily make a payment with a few clicks. No need for complicated bank details or tedious paperwork. It's fast, efficient, and user-friendly."
      buttonRounded={false}
      primaryButtonText=""
      imageSrc="https://s3.ap-southeast-1.wasabisys.com/shulkpaywebsite/paymentlink1.svg"
    />
    <MainFeature1
      subheading={<Subheading></Subheading>}
        heading="Easy to Share and Accept Payment using Payment Links"
        description ="Simplify your financial transactions with our Payment Links. Sharing and accepting payments has never been easier. Generate a unique link, share it with ease, and receive payments effortlessly. It's the hassle-free way to manage your finances, making your life more convenient and efficient"
      buttonRounded={false}
      primaryButtonText=""
      imageSrc="https://s3.ap-southeast-1.wasabisys.com/shulkpaywebsite/paymentlinks2.svg"
      textOnLeft={false}
    />
    <Features
      subheading={<Subheading></Subheading>}
      heading="Payment Links"
      description="Simplify payments with Payment Links. Generate links, share, and get paid effortlessly. 
      Convenience at your fingertips."
      cards={[
        {
          imageSrc: "https://s3.ap-southeast-1.wasabisys.com/shulkpaywebsite/fast.svg",
          title: "Fast",
          description: "Experience lightning-fast transactions with our Payment Links. Your Fast Lane to Convenient Transactions."
        },
        {
          imageSrc: "https://s3.ap-southeast-1.wasabisys.com/shulkpaywebsite/reliable.svg",
          title: "Reliable",
          description: "Our Payment Links are dependable, reliable and secure. Your peace of mind is our priority."
        },
        {
          imageSrc: "https://s3.ap-southeast-1.wasabisys.com/shulkpaywebsite/secured.svg",
          title: "Secure",
          description: "Experience peace of mind with our Secure Payment Page. Your transactions are protected with top-notch security measures, ensuring your data stays safe."
        },
      ]}
      linkText=""
    />
    
    <Footer />
  </AnimationRevealPage>
  )
}

export default PaymentLinks