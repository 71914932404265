import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithBackground.js";
import MainFeature1 from "components/features/TwoColWithButton.js";
// import MainFeature2 from "components/features/TwoColSingleFeatureWithStats.js";
// import MainFeature3 from "components/features/TwoColSingleFeatureWithStats2.js";
import Features from "components/features/ThreeColSimple.js";
// import Features from "components/features/ThreeColWithSideImage.js";

//import SupportIconImage from "images/support-icon.svg";
//import ShieldIconImage from "images/shield-icon.svg";
import CustomerLoveIconImage from "images/simple-icon.svg";

const Subheading = tw.span`uppercase tracking-wider text-sm`;

const BillPayment = () => {
  return (
    <AnimationRevealPage>
      <Header />
      <MainFeature1
        subheading={<Subheading></Subheading>}
        heading="Paying bills has never been easier. "
        description ="With our Bill Payments feature, you can settle your utility bills, credit card payments, and more, all in one place."
        buttonRounded={false}
        primaryButtonText=""
        imageSrc="https://s3.ap-southeast-1.wasabisys.com/shulkpaywebsite/billpayment1.jpg"
      />
      <MainFeature1
        subheading={<Subheading></Subheading>}
        heading="All-in-One Bill Payment platform for businesses"
        description ="Discover the ultimate all-in-one bill payment platform for businesses. Streamline transactions, enhance efficiency, and boost financial convenience."
        buttonRounded={false}
        primaryButtonText=""
        imageSrc="https://s3.ap-southeast-1.wasabisys.com/shulkpaywebsite/billpayment2.jpg"
        textOnLeft={false}
      />
      <Features
        subheading={<Subheading></Subheading>}
        heading="Bharat BillPayment System"
        description="Simplify bill payments with our unified platform. 
        Secure, convenient, and comprehensive bill payment solutions for your ease.
        "
        cards={[
          {
            imageSrc: "https://s3.ap-southeast-1.wasabisys.com/shulkpaywebsite/unified.svg",
            title: "Unified Platform",
            description: "BBPS provides a single, integrated platform for various bill payments."
          },
          {
            imageSrc: "https://s3.ap-southeast-1.wasabisys.com/shulkpaywebsite/coverage.svg",
            title: "Wide Coverage",
            description: "It covers utility bills, insurance premiums, taxes, and more, ensuring comprehensive payment options."
          },
          {
            imageSrc: CustomerLoveIconImage,
            title: "Multiple Channels",
            description: "Users can make payments through various channels like online banking, mobile apps, agents, and kiosks."
          },
        ]}
        linkText=""
      />
      <Footer />
    </AnimationRevealPage>
  )
}

export default BillPayment