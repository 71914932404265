import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithInput.js";
import Features from "components/features/ThreeColWithSideImage.js";
import MainFeature from "components/features/TwoColWithButton.js";
import MainFeature2 from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
import FeatureWithSteps from "components/features/TwoColWithSteps.js";
// import Pricing from "components/pricing/ThreePlans.js";
import Pricing from "components/pricing/TwoPlansWithDurationSwitcher.js";
import Testimonial from "components/testimonials/TwoColumnWithImageAndRating.js";
import FAQ from "components/faqs/SingleCol.js";
import GetStarted from "components/cta/GetStarted";
import Footer from "components/footers/FiveColumnWithBackground.js";
import heroScreenshotImageSrc from "images/hero-screenshot-1.png";
import macHeroScreenshotImageSrc from "images/hero-screenshot-2.png";
import prototypeIllustrationImageSrc from "images/prototype-illustration.svg";
import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";

export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
  const HighlightedText = tw.span`text-primary-500`;

  return (
    <AnimationRevealPage>
      <Hero roundedHeaderButton={true} />
      <Features
        subheading={<Subheading>Features</Subheading>}
        heading={
          <>
            ShulkPay <HighlightedText>Products.</HighlightedText>
          </>
        }
      />
      <MainFeature
        subheading={<Subheading></Subheading>}
        imageSrc={heroScreenshotImageSrc}
        imageBorder={true}
        imageDecoratorBlob={true}
      />
      <FeatureWithSteps
        subheading={<Subheading></Subheading>}
        heading={
          <>
            Easy to <HighlightedText>Get Started.</HighlightedText>
          </>
        }
        textOnLeft={false}
        imageSrc={macHeroScreenshotImageSrc}
        imageDecoratorBlob={true}
        decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
      />
      <MainFeature2
        subheading={<Subheading></Subheading>}
        heading={
          <>
            We Always Abide by Our{" "}
            <HighlightedText>Principles.</HighlightedText>
          </>
        }
        imageSrc={prototypeIllustrationImageSrc}
        showDecoratorBlob={false}
        features={[
          {
            Icon: MoneyIcon,
            title: "Affordable",
            description:
              "Discover affordability at its finest! Our website offers a wide range of budget-friendly products & services without compromising on quality. ",
            iconContainerCss: tw`bg-green-300 text-green-800`,
          },
          {
            Icon: BriefcaseIcon,
            title: "Professionalism",
            description:
              "Experience unmatched professionalism at our website. Our dedicated team ensures top-tier quality and service in every interaction. Trust us for excellence",
            iconContainerCss: tw`bg-red-300 text-red-800`,
          },
        ]}
      />
      <Pricing
        subheading={<Subheading>Pricing</Subheading>}
        heading={
          <>
            Reasonable & Flexible <HighlightedText>Plans.</HighlightedText>
          </>
        }
        // plans={[
        //   {
        //     name: "Personal",
        //     price: "Rs 1999",
        //     duration: "Monthly",
        //     mainFeature: "For Individuals",
        //     features: [
        //       "Payment Page",
        //       "Payment Links (SMS)",
        //       "Recharges",
        //       "Bill Payment",
        //     ],
        //   },
        //   {
        //     name: "Business",
        //     price: "Rs 3999",
        //     duration: "Monthly",
        //     mainFeature: "For Small Businesses",
        //     features: [
        //       "Payment Page",
        //       "Payment Links (SMS & WhatsApp)",
        //       "Recharges",
        //       "Bill Payment",
        //       "SDK",
        //       "API",
        //       "White Label",
        //       "Daily / Monthly Reports",
        //     ],
        //     featured: true,
        //   },
        //   {
        //     name: "Enterprise",
        //     price: "Rs 5999",
        //     duration: "Monthly",
        //     mainFeature: "For Large Companies",
        //     features: [
        //       "Payment Page",
        //       "Payment Links (SMS, WhatsApp & Email)",
        //       "Recharges",
        //       "Bill Payment",
        //       "SDK",
        //       "API",
        //       "White Label",
        //       "Daily / Monthly Reports",
        //       "Reconciliation",
        //       "Agent On Boarding",
        //     ],
        //   },
        // ]}
      />
      <Testimonial
        subheading={<Subheading>Testimonials</Subheading>}
        heading={
          <>
            Our Clients <HighlightedText>Love Us.</HighlightedText>
          </>
        }
        // testimonials={[
        //   {
        //     stars: 5,
        //     profileImageSrc:
        //       "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3.25&w=512&h=512&q=80",
        //     heading: "Amazing User Experience",
        //     quote:
        //       "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.",
        //     customerName: "Charlotte Hale",
        //     customerTitle: "Director, Delos Inc.",
        //   },
        //   {
        //     stars: 5,
        //     profileImageSrc:
        //       "https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=512&h=512&q=80",
        //     heading: "Love the Developer Experience and Design Principles !",
        //     quote:
        //       "Sinor Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
        //     customerName: "Adam Cuppy",
        //     customerTitle: "Founder, EventsNYC",
        //   },
        // ]}
      />
      <FAQ
        subheading={<Subheading>FAQS</Subheading>}
        heading={
          <>
            You have <HighlightedText>Questions ?</HighlightedText>
          </>
        }
        faqs={[
          {
            question: "How does pricing work?",
            answer:
              "Our pricing is based on various factors, including the product or service you are interested in, any customization or additional features you may require, and current market rates. Prices are listed on our website or provided upon request. We strive to offer competitive and transparent pricing to ensure our customers receive fair value for their business.",
          },
          {
            question: "Are there any hidden charges or fees?",
            answer:
              "We strive to be transparent with our pricing, and we do not have any hidden fees or charges. However, it’s always recommended to review the terms and conditions or pricing details of any product or service you are interested in to ensure you have a clear understanding of the costs involved.",
          },
          {
            question: "Can I request a custom pricing package?",
            answer:
              "Yes, for certain products or services, we may offer custom pricing packages based on your specific requirements. Please contact our sales team or customer support to discuss your needs and explore possible custom pricing options.",
          },
          {
            question: "Do you offer discounts or promotions?",
            answer:
              "Yes, we periodically offer discounts and promotions. These may be seasonal, promotional, or based on specific events or customer loyalty. Please check our website or contact our customer support for current discounts or promotions that may apply to your purchase.",
          },
          {
            question: "Do you offer refunds or price adjustments?",
            answer:
              "Refunds and price adjustments may be considered on a case-by-case basis. Please refer to our refund policy or contact our customer support for more information on our refund or price adjustment processes.",
          },
        ]}
      />
      <GetStarted />
      <Footer />
    </AnimationRevealPage>
  );
};
