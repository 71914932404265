import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithBackground.js";
import MainFeature1 from "components/features/TwoColWithButton.js";
// import MainFeature2 from "components/features/TwoColSingleFeatureWithStats.js";
// import MainFeature3 from "components/features/TwoColSingleFeatureWithStats2.js";
import Features from "components/features/ThreeColSimple.js";
// import Features from "components/features/ThreeColWithSideImage.js";
//import TeamCardGrid from "components/cards/ProfileThreeColGrid.js";

import SupportIconImage from "images/support-icon.svg";
// import ShieldIconImage from "images/shield-icon.svg";
// import CustomerLoveIconImage from "images/simple-icon.svg";

const Subheading = tw.span`uppercase tracking-wider text-sm`;
export default () => {
  return (
    <AnimationRevealPage>
      <Header />
      <MainFeature1
        subheading={<Subheading>About ShulkPay</Subheading>}
        heading="Your Trusted Partner!"
        description="We are your trusted partner in navigating the world of payments. With our expertise and innovative solutions, we empower you to streamline collections and payments, ensuring your business thrives. Join us on this journey towards financial success!"
        buttonRounded={false}
        //primaryButtonText="See Portfolio"
        imageSrc="https://images.unsplash.com/photo-1519389950473-47ba0277781c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=768&q=80"
      />
      <MainFeature1
        subheading={<Subheading>Our Vision</Subheading>}
        heading="Empowering SMEs to take Charge of Collections"
        description="Our vision is to empower SMEs, enabling them to take control of their collections efficiently. We believe in simplifying collection processes, facilitating growth, and fostering financial independence for small and medium-sized enterprises. Join us in this journey of empowerment and prosperity!"
        buttonRounded={false}
        //primaryButtonText="Contact Us"
        imageSrc="https://images.unsplash.com/3/doctype-hi-res.jpg?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80"
        textOnLeft={false}
      />
      <Features
        subheading={<Subheading></Subheading>}
        heading="ShulkPay&trade;"
        description="Your own White Label Payments and Collection Platform"
        cards={[
          {
            imageSrc: SupportIconImage,
            title: "Trusted",
            description: "Trustworthy payment solution for your peace of mind."
          },
          {
            imageSrc: "https://s3.ap-southeast-1.wasabisys.com/shulkpaywebsite/secured.svg",
            title: "Highly Secured",
            description: "Your payments & collection are 100% secure."
          },
          {
            imageSrc: "https://s3.ap-southeast-1.wasabisys.com/shulkpaywebsite/reliable.svg",
            title: "Dependable",
            description: "Reliable collections & payments, every time."
          },
        ]}
        linkText=""
      />
     
      <Footer />
    </AnimationRevealPage>
  );
};
