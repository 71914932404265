import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithBackground.js";
import MainFeature1 from "components/features/TwoColWithButton.js";
// import MainFeature2 from "components/features/TwoColSingleFeatureWithStats.js";
// import MainFeature3 from "components/features/TwoColSingleFeatureWithStats2.js";
import Features from "components/features/ThreeColSimple.js";
// import Features from "components/features/ThreeColWithSideImage.js";

//import SupportIconImage from "images/support-icon.svg";
//import ShieldIconImage from "images/shield-icon.svg";
//import CustomerLoveIconImage from "images/simple-icon.svg";

const Subheading = tw.span`uppercase tracking-wider text-sm`;

const PaymentPage = () => {
  return (
    <AnimationRevealPage>
    <Header />
    <MainFeature1
      subheading={<Subheading></Subheading>}
        heading="Quick and Easy to use Payment Page"
        description = "Our Payment Page is designed to make online payments hassle-free. It provides a user-friendly interface where you can securely make payments for products or services with ease "
      buttonRounded={false}
      //primaryButtonText="See Portfolio"
      imageSrc="https://s3.ap-southeast-1.wasabisys.com/shulkpaywebsite/paymentpage1.svg"
    />
    <MainFeature1
      subheading={<Subheading></Subheading>}
        heading="We aim to assist businesses in enabling Digital Payments"
        description ="We're on a mission to revolutionize digital payments for businesses. Our secure and seamless platform simplifies transactions, enhances customer experiences, and drives growth. Join us in shaping the future of payments!"
      buttonRounded={false}
     // primaryButtonText="Contact Us"
      imageSrc="https://s3.ap-southeast-1.wasabisys.com/shulkpaywebsite/paymentpage2.svg"
      textOnLeft={false}
    />
    <Features
      subheading={<Subheading></Subheading>}
      heading="Payment Page"
      description="Secure Transactions, Convenient Payment Options, and Seamless User Experience. Elevate your payment process today!"
      cards={[
        {
          imageSrc: "https://s3.ap-southeast-1.wasabisys.com/shulkpaywebsite/secured.svg",
          title: "Secure",
          description: "Experience peace of mind with our Secure Payment Page. Your transactions are protected with top-notch security measures, ensuring your data stays safe"
        },
        {
          imageSrc: "https://s3.ap-southeast-1.wasabisys.com/shulkpaywebsite/easy.svg",
          title: "Convenient",
          description: "Enjoy the ease and convenience of our Payment Page. Make payments effortlessly with user-friendly options that enhance your experience."
        },
        {
          imageSrc: "https://s3.ap-southeast-1.wasabisys.com/shulkpaywebsite/seamless.svg",
          title: "Seamless",
          description: "Experience seamless transactions with our Payment Page. Streamline the payment process for your convenience and satisfaction."
        },
      ]}
      linkText=""
    />
    
    <Footer />
  </AnimationRevealPage>
  )
}

export default PaymentPage