import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithBackground.js";
import MainFeature1 from "components/features/TwoColWithButton.js";
// import MainFeature2 from "components/features/TwoColSingleFeatureWithStats.js";
// import MainFeature3 from "components/features/TwoColSingleFeatureWithStats2.js";
import Features from "components/features/ThreeColSimple.js";
// import Features from "components/features/ThreeColWithSideImage.js";

// import SupportIconImage from "images/support-icon.svg";
// import ShieldIconImage from "images/shield-icon.svg";
// import CustomerLoveIconImage from "images/simple-icon.svg";

const Subheading = tw.span`uppercase tracking-wider text-sm`;

const PaymentGateway = () => {
  return (
    <AnimationRevealPage>
    <Header />
    <MainFeature1
      subheading={<Subheading></Subheading>}
        heading="Accept payments using Payment Gateway"
        description="Our Payment Gateway is designed with your convenience in mind. It seamlessly integrates with your website or app, providing customers with a user-friendly payment experience. From credit card payments to Net Banking, Debit Card or UPI, our gateway supports a wide range of payment methods to cater to your diverse customer base    "
      buttonRounded={false}
      primaryButtonText=""
      imageSrc="https://s3.ap-southeast-1.wasabisys.com/shulkpaywebsite/paymentgateway1.jpg"
    />
    <MainFeature1
      subheading={<Subheading></Subheading>}
        heading="Payment Gateway for all your collections need!"
        description="Discover a flexible Payment Gateway that caters to all your collection needs. Simplify payments, boost efficiency, and elevate your business."
      buttonRounded={false}
      primaryButtonText=""
      imageSrc="https://s3.ap-southeast-1.wasabisys.com/shulkpaywebsite/paymentgateway2.jpg"
      textOnLeft={false}
    />
    <Features
      subheading={<Subheading></Subheading>}
      heading="Payment Gateway"
      description="Streamline online payments with our Payment Gateway.
      Secure, swift, and simple integration for seamless transactions.
      "
      cards={[
        {
          imageSrc: "https://s3.ap-southeast-1.wasabisys.com/shulkpaywebsite/easy.svg",
          title: "Easy to Integrate",
          description: "Experience seamless integration with our Payment Gateway feature. Easily integrate our secure payment solution into your website or app for a hassle-free payment experience."
        },
        {
          imageSrc: "https://s3.ap-southeast-1.wasabisys.com/shulkpaywebsite/secured.svg",
          title: "Secured",
          description: "Experience peace of mind with ICICI EazyPay Payment Gateway. Your transactions are protected with top-notch security measures, ensuring your data stays safe "
        },
        {
          imageSrc: "https://s3.ap-southeast-1.wasabisys.com/shulkpaywebsite/seamless.svg",
          title: "Seamless",
          description: "Experience seamless transactions with Payment Gateway. Streamline the payment process for your convenience and satisfaction."
        },
      ]}
      linkText=""
    />
   
    <Footer />
  </AnimationRevealPage>
  )
}

export default PaymentGateway